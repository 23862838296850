.product {
    cursor: pointer;
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 5px 5px #000;
    border-radius: 10px;
    padding: 1rem;

    .image {
        width: 100%;
        height: 200px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }
    }

    .price {
        font-family: "Madimi One", sans-serif;
        font-weight: 400;
        font-style: normal;
    }
}

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    .modal-header {
        padding-bottom: 1rem;
        border-bottom: 3px solid;
        margin-bottom: 1rem;
    }

    .modal-content {
        display: block;
        background-color: #fefefe;
        margin: 10vh auto 0;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
        max-width: 600px;
        max-height: 460px;
        height: 80vh;
        border: 3px solid;
        border-radius: 10px;
        box-shadow: 5px 5px;
        overflow: auto;

        @media only screen and (max-width: 600px) {
            width: 100%;
        }

        &.reservation {
            max-width: 1200px;
            max-height: 80vh;
        }
        .image {
            max-width: 100%;
            max-height: 200px;
        }

        .price {
            font-family: "Madimi One", sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 2rem;
        }
    }

    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;

        &:hover,
        &:focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
        }
    }

    &.open {
        display: block;
    }
}