@import "../assets/styles/colors";

main.blog {
  padding: 30px 60px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $c-white;
  text-align: center;
}