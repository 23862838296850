@import "../assets/styles/colors";

main.home {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2rem 2rem;
  background-color: $c-primary;
  text-align: center;
}

main p {
  margin: 1rem;
  border-radius: 15px;
}

select.categories {
  margin-top: 1rem;
  padding: 0.5rem;
  border: 2px solid;
  border-radius: 9px;
  width: 300px;
}

/* Arrow animation */
.tab input:not(:checked) + .tab__label:hover::after {
  animation: bounce .5s infinite;
}

@keyframes bounce {
  25% {
    transform: rotate(90deg) translate(.25rem);
  }
  75% {
    transform: rotate(90deg) translate(-.25rem);
  }
}

ul.categories {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

ul.categories li {
  margin: 0 10px 0 0;
  border-radius: 10px;
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}

ul.categories li:hover, ul.categories li.selected {
  background-color: #dbbee5;
}

/* for mobile make the categories horizontal */
@media only screen and (max-width: 600px) {
  ul.categories {
    flex-wrap: wrap;
    justify-content: center;
  }

  ul.categories li {
    margin: 0 5px 5px 0;
  }
}
