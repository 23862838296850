@import "../assets/styles/colors";

header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $c-primary;

  .logo {
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }

  .siete {
    color: #CA2A7A;
  }

  .page-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 12px;

    a {
      color: inherit;
      text-decoration: none;
    }

    .active {
      font-weight: bold;
    }
  }
}


