@import "../assets/styles/colors";

main.about {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $c-white;
  text-align: center;

  a.outerlink {
    color: $c-primary-strong;
  }

  #map {
    width: 100%;
    min-height: 600px;
  }
}