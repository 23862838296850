@import "../assets/styles/colors";

main.not-found {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $c-white;
  text-align: center;

  .not-found-btn {
    margin-top: 20px;
    padding: 4px 8px;
    border: 2px solid $c-black;
    border-radius: 4px;
    background-color: $c-white;
    font-size: inherit;

    &:hover {
      background-color: $c-primary;
    }
  }
}