@import "./assets/styles/colors";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100vh;
  background-color: $c-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito', sans-serif;
}

body {
  display: flex;
  min-height: 100vh;
  margin: 0;
  align-items: center;
  justify-content: center;
  background-color: #ffe9fe;
  font-family: system-ui, calibri, serif;
  background-repeat: repeat-x;
  background-image: url(./assets/bg.jpg);
  animation: movement 10s linear infinite;
}

@keyframes movement {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 560px 0px;
  }
}

button {
  cursor: pointer;
}

li {
  list-style: none;
}

h1 {
  margin-bottom: 12px;
}

.App {
  display: flex;
  flex-direction: column;
  width: 90vw;
  max-width: 960px;
  max-height: 80vh;
  background-color: $c-white;
  border: 2px solid $c-black;
  border-radius: 10px;
  box-shadow: 5px 5px $c-black;
  overflow: hidden;
  position: relative;
  z-index: 100;
}

main {
  padding-top: 2rem;
  flex: 1;
}

#blossom_container {
	position: absolute;
	width: calc(100% - 40px);
	height: calc(100% - 40px);
}

.petal {
  z-index: 200;
	background: url(http://talktofill.surge.sh/cherry-blossom.png) no-repeat;

	&.petal-style1 {
		width: 45px;
		height: 20px;
		background-position: -31px 0;
	}
	&.petal-style2 {
		width: 42px;
		height: 22px;
		background-position: 0 -23px;
	}
	&.petal-style3 {
		width: 37px;
		height: 24px;
		background-position: 0 -50px;
	}
	&.petal-style4 {
		width: 26px;
		height: 34px;
		background-position: -49px -35px;
	}
}

.tree {
  position: fixed;
  bottom: -20px;
  right: 0;
  height: 100vh;
  filter: blur(2px);
  z-index: 1;
}

#blossom_container {
  z-index: 200;
  pointer-events: none;
}