@import "../assets/styles/colors";

main.post {
  padding: 30px 60px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $c-white;
  text-align: center;

  .post__date {
    opacity: 0.5;
  }

  .post__content {
    width: 100%;
    margin-top: 15px;
    text-align: left;
  }
}