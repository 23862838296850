#reservation button {
    background: #fff;
    color: black;
    border: 3px solid;
    border-radius: 10px;
    font-weight: bold;
    padding: .5rem 1rem;
    text-transform: uppercase;
    margin-bottom: -10px;
}

#reservation iframe {
    border: none;
    height: 80vh;
}