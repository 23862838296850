@import "../assets/styles/colors";

li.blog-item {
  padding: 12px;
  width: 100%;
  border : 2px solid $c-black;
  border-radius: 4px;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: $c-primary;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .blog-item__date {
    font-size: 14px;
    opacity: 0.5;
  }
}